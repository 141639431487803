import Logo from "./Logo";
import css from "../../css/header/menu-mobile.module.css";

import React from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";

const AuthModal = dynamic(() => import("../auth/AuthModal"), { ssr: false });

const MenuMobile = (props) => {
    const {
        isLoggedIn,
        onLogout,
        onClose,
        saleHref,
        saleAs,
        rentHref,
        rentAs,
    } = props;

    return (
        <div className={css.container}>
            <div className={css.header}>
                <div className={css.left}>
                    <span onClick={onClose} className={css.closeButton}>
                        Close
                    </span>
                </div>
                <div className={css.logo}>
                    <Logo size="lg" />
                </div>
                <div className={css.right}>
                    {isLoggedIn && (
                        <span className={css.loginButton} onClick={onLogout}>
                            Logout
                        </span>
                    )}
                    {!isLoggedIn && (
                        <AuthModal className={css.loginButton}>
                            Sign In
                        </AuthModal>
                    )}
                </div>
            </div>

            <ul className={css.menu}>
                <li>
                    <Link
                        href={saleHref}
                        as={saleAs}
                        onClick={onClose}
                        className={css.menuLink}
                    >
                        For Sale
                    </Link>
                </li>
                <li>
                    <Link
                        href={rentHref}
                        as={rentAs}
                        onClick={onClose}
                        className={css.menuLink}
                    >
                        For Rent
                    </Link>
                </li>
                <li>
                    <Link
                        href="/my-property"
                        as="/sell"
                        onClick={onClose}
                        className={css.menuLink}
                    >
                        Sell
                    </Link>
                </li>
                <li>
                    <Link
                        href="/find-agent"
                        as="/real_estate_agents"
                        className={css.menuLink}
                    >
                        Find an Agent
                    </Link>
                </li>
                <li>
                    <Link
                        href="/loans"
                        as="/mortgages/loans/pre-approved"
                        className={css.menuLink}
                    >
                        Loans
                    </Link>
                </li>
                <li>
                    <Link
                        href="/my-property"
                        as="/myproperty/claim_property"
                        className={css.menuLink}
                    >
                        My Property
                    </Link>
                </li>
                <li>
                    <a href="/" className={css.menuLink}>
                        Home Warranty
                    </a>
                </li>
            </ul>

            {!isLoggedIn && (
                <div className={css.signupText}>
                    <AuthModal tab="signup">Sign Up</AuthModal> and start saving
                    homes, searches and more!
                </div>
            )}

            <ul className={css.terms}>
                <li>
                    <Link
                        href="/static-pages?page=about"
                        as="/about/corporate"
                        onClick={onClose}
                    >
                        About
                    </Link>
                </li>
                <li className={css.termsDim}>-</li>
                <li>
                    <Link
                        href="/static-pages?page=terms-of-use"
                        as="/about/terms_of_use"
                        onClick={onClose}
                    >
                        Terms of Use
                    </Link>
                </li>
                <li className={css.termsDim}>-</li>
                <li>
                    <Link
                        href="/static-pages?page=privacy-policy"
                        as="/about/privacy_policy"
                        onClick={onClose}
                    >
                        Privacy
                    </Link>
                </li>
                <li className={css.termsDim}>-</li>
                <li>
                    <Link
                        href="/static-pages?page=contact"
                        as="/about/contact_us"
                        onClick={onClose}
                    >
                        Support
                    </Link>
                </li>
            </ul>
        </div>
    );
};

MenuMobile.propTypes = {
    onLogout: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    saleHref: PropTypes.string.isRequired,
    saleAs: PropTypes.string.isRequired,
    rentHref: PropTypes.string.isRequired,
    rentAs: PropTypes.string.isRequired,
};

export default MenuMobile;
