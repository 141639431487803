import css from "../../css/header/menu.module.css";

import MenuItem from "./MenuItem";
import MenuMobile from "./MenuMobile";
import { useAuth } from "../common/Auth";
import burgerIconRed from "../../static/icons/burger-red.svg";
import burgerIconBlue from "../../static/icons/burger-blue.svg";
import burgerIconBlackRed from "../../static/icons/burger-black-red.svg";

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SearchContext from "../context/Search";

const burgerIcons = {
    red: burgerIconRed,
    blue: burgerIconBlue,
    black: burgerIconBlackRed,
};

const Menu = (props) => {
    const [open, setOpen] = useState(false);
    const { isAuthenticated, logout } = useAuth();
    const search = useContext(SearchContext);

    const toggleMobileMenu = () => {
        setOpen(!open);
    };

    const handleLogout = () => {
        logout();
        toggleMobileMenu();
    };

    const { theme, color, burgerIcon } = props;

    const toggleClass = classNames({
        [css.toggle]: burgerIcon !== "black" && true,
        [css.toggleBlack]: burgerIcon === "black" && true,
        [css.toggleOpen]: open,
    });

    const navClass = classNames({
        [theme.menu]: color === "red" || !color,
        [theme.menuBlue]: color === "blue",
    });

    const BurgerIconImg = burgerIcons[burgerIcon];

    let activeSearchCategory = search ? search.activeSearchCategory : null;
    let searchLocationUrl = search ? search.locationUrl : null;

    const saleAs = searchLocationUrl
        ? `/homesearch/for_sale/${searchLocationUrl}`
        : "/";
    const saleHref = searchLocationUrl
        ? `/search?category=sale&location=${searchLocationUrl}`
        : "/";

    const rentAs = searchLocationUrl
        ? `/homesearch/for_rent/${searchLocationUrl}`
        : "/homes/for_rent";
    const rentHref = searchLocationUrl
        ? `/search?category=rent&location=${searchLocationUrl}`
        : "/index?category=rent";

    return (
        <>
            <nav className={navClass}>
                <ul>
                    <MenuItem
                        activeClassName={css.active}
                        forceActive={activeSearchCategory === "sale"}
                        label="For Sale"
                        href={saleHref}
                        as={saleAs}
                    />
                    <MenuItem
                        activeClassName={css.active}
                        forceActive={activeSearchCategory === "rent"}
                        label="For Rent"
                        href={rentHref}
                        as={rentAs}
                    />
                    <MenuItem
                        activeClassName={css.active}
                        label="Sell"
                        href="/my-property"
                        as="/sell"
                    />
                    <MenuItem
                        activeClassName={css.active}
                        label="Find an Agent"
                        href="/find-agent"
                        as="/real_estate_agents"
                        prefix={["/real_estate_agents", "/real_estate_agent"]}
                    />
                    <MenuItem
                        activeClassName={css.active}
                        label="Loans"
                        href="/loans"
                        as="/mortgages/loans/pre-approved"
                    />
                    <MenuItem
                        activeClassName={css.active}
                        label="My Property"
                        href="/my-property"
                        as="/myproperty/claim_property"
                    />
                    <li>
                        <a href="/">Home Warranty</a>
                    </li>
                </ul>
            </nav>

            <div className={toggleClass} onClick={toggleMobileMenu}>
                <BurgerIconImg />
            </div>

            {open && (
                <MenuMobile
                    saleHref={saleHref}
                    saleAs={saleAs}
                    rentHref={rentHref}
                    rentAs={rentAs}
                    onLogout={handleLogout}
                    onClose={toggleMobileMenu}
                    isLoggedIn={isAuthenticated}
                />
            )}
        </>
    );
};

Menu.defaultProps = {
    burgerIcon: "red",
};

Menu.propTypes = {
    theme: PropTypes.object.isRequired,
    color: PropTypes.string,
    burgerIcon: PropTypes.string,
};

export default Menu;
